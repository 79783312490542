<template>
  <FocusTrap>
    <div class="card" id="mycard">
      <div class="card-header header-elements-inline">
        <legend class="font-weight-semibold text-uppercase font-size-sm">
          <i class="icon-file-text2"></i>
          Enter Tank information
        </legend>

        <div class="header-elements">
          <div class="list-icons">
            <a class="list-icons-item" data-action="reload"></a>
            <a class="list-icons-item" data-action="remove" @click="closeWindow"></a>
          </div>
        </div>
      </div>

      <div class="card-body">

        <div class="form-group row">
          <label class="col-md-2 col-form-label">Name:</label>
          <div class="col-md-10">
            <input type="text" id="txtname" class="form-control" placeholder="Tank Name" v-if="tank" v-model="tank.name">
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-2 col-form-label">Product:</label>
          <div class="col-md-4">
            <select class="form-control" v-if="tank" v-model="tank.item_id">
              <option value="101">Petrol</option>
              <option value="102">Diesel</option>
              <option value="103">Speed</option>
            </select>
          </div>

          <label class="col-md-2 col-form-label">DIP Chart:</label>
          <div class="col-md-4">
            <select class="form-control" v-if="tank" v-model="tank.dipchart_id">
              <option value="1">-</option>
              <option v-for="chart in dipcharts" v-bind:value="chart.id">
                {{ chart.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-2 col-form-label">Capacity:</label>
          <div class="col-md-4">
            <input type="number" class="form-control text-right" placeholder="Tank Open Stock" v-if="tank" v-model="tank.capacity">
          </div>

          <label class="col-md-2 col-form-label">Dip:</label>
          <div class="col-md-4">
            <input type="text" class="form-control text-right" placeholder="Opening Dip" v-if="tank" v-model="tank.opendip">
          </div>
        </div>

        <div class="text-right">
          <SaveButton @handle_save_action="saveDocument"></SaveButton>
        </div>

      </div>
    </div>
  </FocusTrap>
</template>

<script>
import { userService } from '@/store/auth-header.js'
import { store } from '@/store/store.js'

export default {
  name: 'TankForm',
  store,
  data () {
    return {
      tank: JSON.parse('{"id":0,"name":"","brn_id":0,"cmp_id":0,"item_id":0,"dipchart_id":0,"capacity":0,"openstock":0,"opendip":0,"item":{"id":0,"name":"","group_id":0,"unit_id":0,"salerate":0},"dipchart":{"id":0,"name":""},"branch":{"id":0,"name":""}}'),
      dipcharts : [],
    }
  },
  component: {

  },
  props: {
    mytank: {
      type: Object,
      required: true,
      default: JSON.parse('{"id":0,"name":"","brn_id":0,"cmp_id":0,"item_id":0,"dipchart_id":0,"capacity":0,"openstock":0,"opendip":0,"item":{"id":0,"name":"","group_id":0,"unit_id":0,"salerate":0},"dipchart":{"id":0,"name":""},"branch":{"id":0,"name":""}}')
    }
  },
  beforeMount () {
    this.tank = this.mytank; // save props data to itself's data and deal with it
  },
  created () {
    this.$data.tank = JSON.parse('{"id":0,"name":"","brn_id":0,"cmp_id":0,"item_id":0,"dipchart_id":0,"capacity":0,"openstock":0,"opendip":0,"item":{"id":0,"name":"","group_id":0,"unit_id":0,"salerate":0},"dipchart":{"id":0,"name":""},"branch":{"id":0,"name":""}}')
  },
  mounted () {
    $('.form-control-select2').select2();
    $('#txtname').focus();
    this.loadData();
  },
  methods: {
    closeWindow () {
      this.$emit('tank_window_closed');
    },
    loadData () {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      self.dipcharts = [];

      fetch(`${process.env.VUE_APP_ROOT_API}api/xpos/dipcharts`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (_.isArray(resp.data)) {
            self.dipcharts = resp.data;
          }
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' });
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' });
      })
    },
    saveDocument () {
      const self = this;

      try {
        if (self.$data.tank.name.toString().length < 3) {
          alert('Invalid Name');
          return
        }

        // self.$data.tank.outlet.id = parseInt(self.$store.state.user.branch.id);
        // self.$data.tank.item.id = parseInt(self.$data.tank.item.id);
        // self.$data.tank.dipchart.id = parseInt(self.$data.tank.dipchart.id);

        self.$data.tank.outlet_id = parseInt(self.$store.state.user.branch.id);
        self.$data.tank.item_id = parseInt(self.$data.tank.item_id);
        self.$data.tank.dipchart_id = parseInt(self.$data.tank.dipchart_id);
        self.$data.tank.capacity = parseInt(self.$data.tank.capacity);
        self.$data.tank.dip = parseInt(self.$data.tank.dip);

        $('#mycard').block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });


        const requestOptions = {
          method: (self.$data.tank.id == 0 ? 'POST' : 'PUT'),
          mode: 'cors',
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.tank)
        }

        fetch(`${process.env.VUE_APP_ROOT_API}api/xpos/tank`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            swal({
              title: 'Success!', text: resp.msg, type: 'success', onClose: () => {
                $('#txtname').focus()
              }, timer: 1500
            });
            self.tank = JSON.parse('{"id":0,"name":"","brn_id":0,"cmp_id":0,"item_id":0,"dipchart_id":0,"capacity":0,"openstock":0,"opendip":0,"item":{"id":0,"name":"","group_id":0,"unit_id":0,"salerate":0},"dipchart":{"id":0,"name":""},"branch":{"id":0,"name":""}}');
            self.$emit('tank_saved', resp.data);
          } else {
            swal({
              title: 'Oops!', text: resp.msg, type: 'error', onClose: () => {
                $('#txtname').focus()
              }, timer: 3000
            });
          }
        }).catch(function (err) {
          swal({
            title: 'Oh noes!', text: err.toString(), type: 'error', onClose: () => {
              $('#txtname').focus()
            }, timer: 3000
          });
        }).finally(()=>{
          $('#mycard').unblock();
        });

      }catch (e) {
        alert(e);
      }
    }
  }
}
</script>

<style scoped>
  input:focus {
    background: #feff00;

  }

  textarea:focus {
    background: #feff00;

  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    color: #0a0a0a!important;
  }
</style>
